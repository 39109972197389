import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Auth from '../utils/auth'; // Import Auth
import { FaYoutube } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTheme } from './ThemeContext';

const FileUploadForm = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;
  const pagesToShow = 5;

  const { theme } = useTheme();

  useEffect(() => {
    fetchImages();
  }, [currentPage]); // Fetch images when currentPage changes

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFile(files); // Store the array of selected files
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!Auth.loggedIn()) {
        alert('Please log in to upload images');
        return;
      }

      const formData = new FormData();
      file.forEach((f) => {
        formData.append('file', f); // Append each file to the FormData
      });

      await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      alert('Files uploaded successfully');

      setFile(null);
      fetchImages();
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Failed to upload files');
    }
  };

  const fetchImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/images');
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const lastPage = Math.ceil(images.length / imagesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(lastPage, startPage + pagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded ${currentPage === i ? 'bg-blue-700' : ''}`}
          onClick={() => paginate(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <div className="container mx-auto p-4">
        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} text-center shadow-md p-4 rounded-lg mb-4`}>
          <h1 className="text-xl md:text-2xl lg:text-3xl font-bold mb-1">Welcome to Gallery page (የምስል ክምችት ገፅ)</h1>
          <p className="text-lg text-gray-400 p-2">Explore our image and Video gallery</p>
          {loading && <p className="text-blue-500">Loading images...it might take a minute to load images እባክዎትን በትግስት ይጠብቁ</p>}
        </div>

        {Auth.loggedIn() && (
          <form onSubmit={handleSubmit} className="mb-4">
            <input type="file" onChange={handleFileChange} className="mb-2" multiple />
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">
              Upload File
            </button>
          </form>
        )}

        <div className="grid grid-cols-4 gap-4">
          {currentImages.map((image, index) => (
            <div key={index} className="image-item cursor-pointer border border-gray-300" onClick={() => handleImageClick(index + indexOfFirstImage)}>
              {image.data ? (
                <img src={`data:${image.contentType};base64,${image.data}`} alt={`Image ${index + indexOfFirstImage}`} className="w-full h-auto object-cover" style={{ aspectRatio: '1/1' }} />
              ) : (
                <p className="text-red-700">Error: Image data not found</p>
              )}
            </div>
          ))}
        </div>

        {selectedImageIndex !== null && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
            <div className="max-w-3xl w-full p-4 bg-white rounded-lg">
              <button onClick={handleCloseModal} className="absolute top-2 right-2 text-red-600 hover:text-green-900 focus:outline-none">
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              {images[selectedImageIndex] && (
                <img src={`data:${images[selectedImageIndex].contentType};base64,${images[selectedImageIndex].data}`} alt={`Image ${selectedImageIndex}`} className="w-full h-auto" />
              )}
              <button onClick={handlePrevImage} className="absolute top-1/2 transform -translate-y-1/2 left-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">
                <FaChevronLeft />
              </button>
              <button onClick={handleNextImage} className="absolute top-1/2 transform -translate-y-1/2 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">
                <FaChevronRight />
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-center items-center mt-4">
          {images.length > imagesPerPage && (
            <div>
              {currentPage !== 1 && (
                <button onClick={() => paginate(currentPage - 1)} className="mx-1 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-3 rounded">
                  Previous
                </button>
              )}
              {renderPageNumbers()}
              {currentPage !== lastPage && (
                <button onClick={() => paginate(currentPage + 1)} className="mx-1 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded">
                  Next
                </button>
              )}
            </div>
          )}
        </div>

        <div className='flex justify-center items-center mt-4 border bg-slate-600 rounded-lg p-4 '>
          <a href="https://www.youtube.com/@StMaryutah-eotc" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="text-4xl text-red-500" />
          </a>
          <a href="https://www.youtube.com/@StMaryutah-eotc" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline ml-2">
            Watch our videos of various Mass and Feast celebrations  / የተለያዩ የቅዳሴ እንዲሁም የበዓላት አከባበር ቪዲዮችን ይመልከቱ
          </a>
        </div>
      </div>
    </div>
  );
};

export default FileUploadForm;
