import React, { useState } from 'react';
import EOTCmodal from './EOTCmodal';
import { useTheme } from '../components/ThemeContext';

import ScrollToTopButton from "../components/ScrollToTopButton";

import mainpic from '../images/mainpic.png';
import Abune1 from '../images/Abune1.png';
import Abune2 from '../images/Abune2.png';
import Aba2 from '../images/Aba3.jpg';
import Abune3 from '../images/Abune-Natnael.jpg';
import sleshi from '../images/sleshi.jpg';
import michael from '../images/michael.jpg';
import leul from '../images/leul.JPG';
import guadie from '../images/guadie.jpg';
import gebrehiwot from '../images/gebrehiwot.jpg';
import seyoum from '../images/seyoum.JPG';
import homepic from '../images/homepic.jpg';
import fenan from '../images/fenan.JPG';
import wondwosen from '../images/wondwosen.jpg';
import anbesse from '../images/anbesse.jpg';
import getachew from '../images/getachew.jpg';
import yemane from '../images/yemane.JPG';
import nahom from '../images/nahom.JPG';

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { theme } = useTheme();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>

      <ScrollToTopButton />
      <div className="container mx-auto">
      <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
        <h2 className="text-xl md:text-2xl ld-text-3xl text-center mb-2">About Utah St. Mary's Ethiopian Orthodox Tewahedo Church</h2>
        <h2 className="text-xl md:text-2xl ld-text-3xl text-center mb-2">ስለ ቤተክርስቲያናችን አመሰራረትና ጠቅላላ መረጃ</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            <img src= {mainpic} alt="Main Pic" className="w-full h-auto mb-4" />
            <h2 className="text-2xl bg-gray-900 text-slate-100 border border-slate-500 text-center rounded-full ">ቤተ ክርስቲያናችን</h2>
            <p className="indent-7 p-2">
            የቅድስት ማርያም የኢትዮጵያ ኦርቶዶክስ ተዋሕዶ ቤተ ክርስቲያን በ <a href ="https://www.google.com/maps/dir//688+N+Main+St+Layton,+UT+84041+USA/@41.0706857,-111.9764993,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x875303b62d3742e9:0xb9560e2c42b2c459!2m2!1d-111.9764993!2d41.0706857?entry=ttu" className='text-blue-700 hover:text-red-700'>688 North main street, Layton, Utah</a> የምትገኝ ኦርቶዶክሳዊት ተቋም ናት።
           </p>
           <p className="indent-7 p-2">
            ቤተ ክርስቲያናችን በ 2005 እኤአ በቅዱስነታቸው በሊቀ ጳጳስ አቡነ ቴዎፍሎስ ተመሰረተች። የፈጣሪ ፈቃድ ሆኖ እስከ አሁኗ ሰአት ድረስ ምእመናን በቅዳሴ ማህሌት፣ ቅዱስ ቁርባንን፣ ሃይማኖታዊ ትምህርትን፣ እና መንፈሳዊ ስብከቶችን ጨምሮ የተለያዩ ማህበራዊ አገልግሎቶችን በማቅረብ ትገኛለች።
            </p>
            <p className="indent-7 p-2">
            ቤተ ክርስቲያናችን ከተቋቋመችበት እለት እስከ አሁኗ ሰአት ድረስ የእግዚብሔር ፈቃዱ ሆኖ ልጆቿን ከመላው ዩታ ከያሉበት ሰብስባ ፈጣሪያቸውን አንዲያመሰግኑ፣ በአላትን እንዲያከብሩ፣ ስርአተ ጥምቀትን እንዲሁም የተለያዩ መንፈሳዊ አገልግሎቶችን እንዲያገኙ ጠንክራ እየሰራች ያለች ሲሆን ወዳጆቿን መጥታችሁ የእመቤታችን በረከቷንና ረድኤቷን እንድታገኙ ስትል በልኡል እግዚአብሔር ስም መልእክቷን ታስተላልፋለች።
            </p>
            
            <button className="text-slate-100 hover:bg-pink-800 mt-2 bg-pink-700 shadow-lg shadow-slate-900 rounded-lg p-2" onClick={openModal}>more about EOTC / ስለ ኢኦተቤ ተጨማሪ መረጃ</button>
            <EOTCmodal isOpen={isModalOpen} closeModal={closeModal} />

          </div>
          <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            
            <h2 className="text-2xl bg-gray-900 text-slate-100 border border-slate-500 text-center rounded-full mt-2">Our Church</h2>
            <p className="indent-7 p-2 mt-2">
            St. Mary's Ethiopian Orthodox Tewahedo Church is an Orthodox institution located at <a href ="https://www.google.com/maps/dir//688+N+Main+St+Layton,+UT+84041+USA/@41.0706857,-111.9764993,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x875303b62d3742e9:0xb9560e2c42b2c459!2m2!1d-111.9764993!2d41.0706857?entry=ttu" className='text-blue-700 hover:text-red-700'> 688 North Main Street, Layton </a> in Utah.
            </p>
            <p className="indent-7 p-2">Our church was established in 2005 by His Holiness Archbishop Abune Theoflos. As the will of the God, until now, Our Church has been providing various social services to the faithful including Mass, Holy Communion, religious education, and spiritual sermons.</p>

            <p className="indent-7 p-2">Since its inception, our Church has been working hard to gather her children from all over Utah to thank their Creator, celebrate their holidays, receive baptism and various spiritual services.</p>
            <p className="border border-gray-500 text-center text-blue-600 rounded-lg ">Use google map for our location</p>
            <div className="bg-white p-6 rounded-lg shadow-md mt-4">
          <iframe
            className="w-full h-80"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.0566759293055!2d-111.97035068458607!3d41.06793192480489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752f2697b880e17%3A0xce62ee96b29f6f3f!2s688%20N%20Main%20St%2C%20Layton%2C%20UT%2084041%2C%20USA!5e0!3m2!1sen!2suk!4v1648030764692!5m2!1sen!2suk"
            title="St. Mary's Ethiopian Orthodox Church Location"
            allowFullScreen
          ></iframe>
         </div>

          </div>
          </div>

        {/* Administration section */}
        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            <h3 className="text-2xl mb-4 p-2 bg-gray-900 text-slate-400 text-center rounded-lg">Clergy And Board Members/ አገልጋይ ካህናትና የቦርድ አባላት</h3>
            <p className="mb-4 p-4 border rounded-lg border-slate-400">
                St. Mary's Ethiopian Orthodox Church is led by a dedicated team of clergy and lay leaders who are committed to serving the spiritual and pastoral needs of our community. Our administration is responsible for overseeing the day-to-day operations of the church, managing staff, and ensuring the efficient delivery of services and programs. We are dedicated to fostering a welcoming and inclusive environment where all members of our congregation can grow in faith and fellowship.
            </p>
            <div className='flex justify-center'>
            <img src={homepic} alt="logo1" className="h-auto object-cover mb-4" />
            </div>
        </div>

        <div className="text-center lg:text-3xl md:text-2xl border-b rounded-full text-lg  text-slate-100 p-2 bg-gray-900 ">
        <h2 className="text-2xl text-center mb-4 p-1 m-1 text-blue-500 bg-gray-900"> Our Church hierarchical Structure</h2>
        </div>

  <div className="container mx-auto py-4">
  <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'}  flex flex-wrap justify-center gap-8 rounded-lg p-4`}>
    {/* Left Image and Job Occupation */}
    <div className="w-full lg:w-1/3 flex flex-col items-center border border-slate-400 p-1 rounded-lg">
      <img src = {Abune1} alt="Abune1" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <p>ብፁእ ወቅዱስ አቡነ ማቲያስ ቀዳማዊ ፓትርያክ ርእሰ ሊቀ ጳጳስ ዘአክሱም ወእጨጌ ዘመንበረ ተክለ ሃይማኖት </p>
        <p>His Holiness Abune Mathias I Patriarch and Catholicos of Ethiopia, Ichege of the See of Saint Taklehaimanot, and Archbishop of Axum.</p>
        </div>
    </div>

    {/* Right Image and Job Occupation */}
    <div className="w-full lg:w-1/3 flex flex-col items-center border border-slate-400 p-1 rounded-lg">
      <img src={Abune3} alt="Abune2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-2xl mb-2">ሊቀ ጳጳስ አቡነ ናትናኤል</h2>
        <p>በሰሜን አሜሪካ የኮሎራዶና አካባቢው ሃገረ ስብከት ሊቀነ ጳጳስ </p>
        <p>His Holiness Abune Natinael Archbishop of Denver and surrounding areas in North America</p>
         </div>
    </div>

    <div className="w-full lg:w-1/3 flex flex-col items-center border border-slate-400 p-1 rounded-lg">
      <img src={Abune2} alt="Abune2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-2xl mb-2">ሊቀ ጳጳስ አቡነ ቴዎፍሎስ</h2>
        <p>የቤተ-ክርስትያኗ መስራች እና በሰሜን ካሊፎርንያ ፣ ኔቫዳና አሪዞና ሃገረ ስብከት ሊቀ ጳጳስ</p>
        <p>His Holiness Abune Theophilos, Founder of the Church and Archbishop of Northern California, Nevada and Arizona</p>
         </div>
    </div>
  </div>

   {/* Grid for 10 People */}
   <div className={`${theme === 'light' ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-900'} mt-8 rounded-lg p-4`}>
   <h2 className="text-2xl text-center mb-4 border-b rounded-full p-1 m-1 text-blue-500 bg-gray-900">አገልጋይ ካህናት / Clergy</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">

    {/* Person 1 */}
    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={Aba2} alt="Person 1" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">መልአከ ጽዮን አባ ፍሥሐ መኮንን</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>የቤተክርስትያናችን አስተዳዳሪ</p>
        <p>Church Administrator</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={leul} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">ሊቀ ዲን. ልኡል መንግስቱ (Dn. Leul Mengestu)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>የስብከተ ወንጌል ሃላፊ</p>
        <p>Preacher</p>
        </div>
      </div>
    </div>

    {/* Person 2 */}
    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={gebrehiwot} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        
      <h2 className="text-xl mb-2">ዲን. ገብረ-ህይወት (Dn. Gebre-Hiwot)</h2>
      <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>የቤተክርስትያናችን ዲያቆን</p>
        <p>Deacon of Our Church</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={fenan} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        
      <h2 className="text-xl mb-2">ዲን. ፍናን ገብረ (Dn. Fenan Gebre)</h2>
      <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>የቤተክርስትያናችን ዲያቆን</p>
        <p>Deacon of Our Church</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={nahom} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        
      <h2 className="text-xl mb-2">ዲን. ናሆም ሂሳቡ (Dn. Nahom Hisabu)</h2>
      <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>የቤተክርስትያናችን ዲያቆን</p>
        <p>Deacon of Our Church</p>
        </div>
      </div>
    </div>
    </div>
    

  {/* Board Members */}
  <div className={`${theme === 'light' ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-900'} mt-8 rounded-lg p-4`}>
    <h2 className="text-2xl text-center mb-4 border-b rounded-full p-1 m-1 text-blue-500 bg-gray-900">የቦርድ አባላት/ Board Memebers</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
      {/* Person 5 */}
    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={sleshi} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ ስለሽ ታደሰ (Mr. Sleshi Tadesse)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ሊቀመንበር እና ሰብሳቢ</p>
        <p>Chairman of the church</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={michael} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">ፕሮ. ሚካኤል ማሞ (Prof. Michael Mamo)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ምክትል ሊቀመንበር እና ፀሃፊ</p>
        <p>Vice Chairman and Secretary</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={seyoum} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ ስዩም አበበ (Mr. Seyoum Abebe)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ገንዘብ ያዥ</p>
        <p>Finance Department</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={wondwosen} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ ወንድወሰን አቶምሳ (Mr. Wondwosen Atomsa)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ንብረት ክፍል</p>
        <p>Asset Management</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={guadie} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ ጓዴ ካሴ (Mr. Guadie Kassie)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ሂሳብ ሹም</p>
        <p>Accountant</p>
        </div>
      </div>
    </div>
    
    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={getachew} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ ጌታቸው በቀለ(Mr. Getachew Bekele)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ማህበራዊ አማካሪ</p>
        <p>Commmunity counselor</p>
        </div>
      </div>
    </div>
    
    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={anbesse} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ አንበሴ ሃይለማርያም (Mr. Anbesse Hailemariam)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ማህበራዊ አማካሪ</p>
        <p>Commmunity counselor</p>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center border border-slate-400 rounded-lg p-1">
      <img src={yemane} alt="Person 2" className="rounded-full w-48 h-48 object-cover mb-4" />
      <div className="border border-slate-400 p-6 rounded-lg text-center">
        <h2 className="text-xl mb-2">አቶ የማነ ሃይሌ (Mr. Yemane Haile)</h2>
        <div className={`${theme === 'light' ? 'bg-blue-200' : 'bg-blue-400'} text-gray-800 p-1 rounded-lg`}>
        <p>ማህበራዊ አማካሪ</p>
        <p>Commmunity counselor</p>
        </div>
      </div>
    </div>

    </div>
    </div>
    
        </div>
      </div>
      </div>
    </div>
  );
};

export default About;
